import { API_LAUNCHPAD, GRAPH_API_NFT_LAUNCHPAD } from 'config/constants/endpoints'
import request, { gql } from 'graphql-request'
import { ApiLaunchpad, SummaryLaunchpadData, UserLaunchpadType } from './types'
import { baseCardFields, baseUserFields } from './queries'

/**
 * Fetch static data from a collection using the API
 * @returns
 */
export const getLaunchpadsApi = async (collection: string): Promise<ApiLaunchpad> => {
  const res = await fetch(`${API_LAUNCHPAD}/all/${collection}`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error(`API: Failed to fetch NFT launchpad`, res.statusText)
  return null
}

export const getUserLaunchpad = async (account: string): Promise<UserLaunchpadType> => {
  try {
    const res = await request(
      GRAPH_API_NFT_LAUNCHPAD,
      gql`
        query getUser($id: String) {
          user(id: $id) {
            ${baseUserFields}
          }
        }
      `,
      { id: account.toLowerCase() },
    )

    return res?.user
  } catch (error) {
    console.error('Failed to fetch User Launchpad', error)
    return null
  }
}

/**
 * Get summary sell launchpad NFTs
 * @param first Number of nfts to retrieve
 * @returns NftTokenSg[]
 */
export const getSummaryLaunchpadNfts = async (first = 5): Promise<{ [key: string]: SummaryLaunchpadData }> => {
  try {
    const res = await request(
      GRAPH_API_NFT_LAUNCHPAD,
      gql`
        query getCards($first: Int) {
          cards(first: $first) {
            ${baseCardFields}
          }
        }
      `,
      { first },
    )

    return res.cards.reduce((sum, card) => ({ ...sum, [card.id]: card }), {})
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return {}
  }
}
