import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { CopyButton } from '@pancakeswap/uikit/src/components/CopyButton'
import Image from 'next/image'
import { getJoiNFTAddress } from 'utils/addressHelpers'
import { getBlockExploreLink } from 'utils'
import ListNFT from './components/ListNFT'

const BlockHome = styled.div``
const BlockHomeWrap = styled.div`
  padding: 80px 0px 100px 0px;
`
const BlockContract = styled.div`
  width: 100%;
  max-width: 500px;
  height: 37.5px;
  border: 2px solid rgb(39, 68, 33);
  background: #fff;
  display: flex;
  z-index: 999;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 10px auto 0px auto;
  position: relative;
  @media screen and (max-width: 1199px) {
    max-width: 300px;
  }
`

const StyledBlockMarquee = styled.div`
  width: calc(100% - 35px);
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 5px;
`

const StyledMarquee = styled(Marquee)`
  font-size: 1.6rem;
  color: #000;
  line-height: calc(1.6rem * 1.25);
  div {
    font-family: SUISSE_BLACK;
    padding: 0px 12.5px;
    @media screen and (max-width: 1199px) {
      padding: 0px 10px;
    }
  }
  @media screen and (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: calc(1.25rem * 1.25);
  }
`

const BlockText = styled.div`
  font-family: SUISSE_BOLD;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.25);
`
const ImgContract = styled(Image)`
  width: 24px;
  object-fit: contain;
`
const CopyButtonWrap = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-height: 24px;
  background: rgb(39, 68, 33);
  border-radius: 5px;
  margin: 0px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CopyBtn = styled(CopyButton)`
  width: 14px;
  height: 14px;
  fill: #fff;
  @media screen and (max-width: 1199px) {
    width: 14px;
    height: 14px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  return (
    <BlockHome>
      <BlockHomeWrap>
        <BlockContract>
          <ImgContract src="/images/icContract.webp" alt="" width="0" height="0" sizes="100vw" />
          <StyledBlockMarquee>
            <StyledMarquee>
              <BlockText>Smart Contract NFT : {getJoiNFTAddress()}</BlockText>
            </StyledMarquee>
          </StyledBlockMarquee>
          <CopyButtonWrap>
            <CopyBtn text={getBlockExploreLink(getJoiNFTAddress(), 'address')} tooltipMessage="Copied" />
          </CopyButtonWrap>
        </BlockContract>
        <ListNFT />
      </BlockHomeWrap>
    </BlockHome>
  )
}

export default Home
