import React from 'react'
import styled from 'styled-components'

interface TitleListProps {
  nameList?: string
  className?: string
}
const BlockTitleList = styled.div`
  width: 100%;
  height: 100px;
  margin: 30px 0px 0px 0px;
`
const BlockWrapContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0px 0px 0px !important;
  @media screen and (max-width: 1199px) {
    align-items: center;
    justify-content: center;
    padding: 30px 15px 0px 15px !important;
  }
`
const BlockNameList = styled.div`
  @media screen and (max-width: 1199px) {
    font-size: 2.4rem;
    line-height: calc(2.4rem * 1.25);
  }
  font-size: 3rem;
  line-height: calc(3rem * 1.25);
  color: ${({ theme }) => theme.colors.text};
  font-family: SUISSE_BOLD;
`

const TitleList: React.FC<TitleListProps> = ({ nameList, className }) => {
  return (
    <BlockTitleList className={className}>
      <BlockWrapContent>
        <BlockNameList>{nameList}</BlockNameList>
      </BlockWrapContent>
    </BlockTitleList>
  )
}

export default TitleList
