import { SWRConfig } from 'swr'
import { getSettingsApi } from 'state/settings/helpers'
import { GetStaticProps } from 'next'
import Home from 'views/Home'

const IndexPage = ({ settings }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          settings,
        },
      }}
    >
      <Home />
    </SWRConfig>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const results = {
    launchpads: [],
    featuredCollections: [],
    settings: {},
    banners: []
  }

  try {
    const settings = await getSettingsApi()
    results.settings = settings;
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      console.error('Error when fetching setting', error)
    }
  }

  return {
    props: results,
    revalidate: true
  }
}

export default IndexPage
